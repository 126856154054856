<template>
  <div class="admin-feed-block AdminCreatorVerifications">
    <AdminStatisticsFilter :statistics="statistics" :title="$locale['creators_verification']" />
    <div :class="`statistics-block __general ${loading ? '__loading' : '__loaded'}`">
      <div class="statistics-block-content">
        <AdminVerificationsCard
          v-for="(user, index) in verifications"
          :key="index"
          :user="user"
          :card="{ desc: periodLabel, numLabel: date_locale(user.date) }"
        />
        <LoadMore :morepage="statistics.hasMore" @more="getStatistics({ page: statistics.page + 1 })" />
      </div>
    </div>
  </div>
</template>

<script>
import AdminVerifications from "./AdminVerifications.vue";
export default {
  mixins: [AdminVerifications],
  components: {
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
  },
  beforeMount: function() {
    this.query = "onlyCreator=true";
  },
};
</script>
